export const mixinIndex = {
  data() {
    return {
      // 滑块验证=图片
      picArray:[
        require("@/assets/images/verify_img/images_a.png"),
        require("@/assets/images/verify_img/images_b.png"),
        require("@/assets/images/verify_img/images_c.png"),
        require("@/assets/images/verify_img/images_d.png"),
        require("@/assets/images/verify_img/images_e.png"),
        require("@/assets/images/verify_img/images_f.png"),
        require("@/assets/images/verify_img/images_g.png"),
        require("@/assets/images/verify_img/images_h.png"),
        require("@/assets/images/verify_img/images_i.png"),
        require("@/assets/images/verify_img/images_j.png"),
        require("@/assets/images/verify_img/images_k.png"),
        require("@/assets/images/verify_img/images_l.png"),
        require("@/assets/images/verify_img/images_m.png"),
        require("@/assets/images/verify_img/images_n.png"),
      ],
      noteList:[
        {name:'环境保持安静',img:require("@/assets/images/img/img1.png")},
        {name:'普通话录音',img:require("@/assets/images/img/img2.png")},
        {name:'推荐wav,fiv无损音质',img:require("@/assets/images/img/img3.png")},
        {name:'推荐48k采样率',img:require("@/assets/images/img/img4.png")},
      ],
      poorList:[
        {name:'非人物照',img:require("@/assets/images/img/user_img1.png")},
        {name:'五官遮挡',img:require("@/assets/images/img/user_img2.png")},
        {name:'模糊不清',img:require("@/assets/images/img/user_img3.png")},
        {name:'衣着不当',img:require("@/assets/images/img/user_img4.png")},
        {name:'面部过大',img:require("@/assets/images/img/user_img5.png")},
        {name:'多张人脸',img:require("@/assets/images/img/user_img6.png")},
      ]
    }
  },
  computed: {

  },
  created() {},
  mounted() {},
  methods: {},
}
