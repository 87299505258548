<!-- @Author: Yu_Bo -->
<template>
    <div class='login_main'>
        <div class="main">
            <div class="main_title">云川科技</div>
            <div class="main_form">
                <el-form :model="loginForm" :rules="siginRules" ref="loginForm" label-width="0px">
                    <el-form-item prop="account">
                        <div class="form_title">用户名</div>
                        <div class="form_input">
                            <el-input v-model="loginForm.account" placeholder="请输入用户名称"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item prop="password">
                        <div class="form_title">密码</div>
                        <div class="form_input">
                            <el-input v-model="loginForm.password" show-password placeholder="请输入密码"></el-input>
                        </div>
                    </el-form-item>
                </el-form>
                <div class="form_btn">
                    <el-button type="primary" :loading="btnloading" class="btn_txt btnBgColor_blue" @click="submitForm('loginForm')">登录</el-button>
                </div>
            </div>
        </div>
        <!-- 发送验证码滑块验证 -->
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="440px" top="30vh">
            <div class="title" slot="title">
                <div class="name">请完成安全验证</div>
            </div>
            <div class="verify_main">
                <div class="ver_succ" v-if="succShow">{{succTime}} 验证成功</div>
                <div class="ver_err" v-if="errShow">验证失败</div>
                <slide-verify :l="40" :r="6" :w="400" :h="200" :imgs="picArray" slider-text="向右滑动完成验证" ref="slideblock" @success="onSuccess" @fail='onFail' @fulfilled='onFulfilled'></slide-verify>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    mixinIndex
} from "@/mixin/index"
export default {
    mixins: [mixinIndex],
    components: {},
    props: {},
    data() {
        return {
            // 登录验证
            siginRules: {
                account: [
                    { required: true, message: '请输入用户名称', trigger: 'blur' },
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                ],
            },
            loginForm: {
                account: '',
                password: ''
            },
            disabled: false,
            codeTxt: '获取验证码',
            codeTime: 60,
            timer: null,
            //
            btnloading: false,
            //
            dialogVisible: false,
            succShow: false,
            succTime: 0,
            errShow: false,
        }
    },
    computed: {},
    watch: {},
    created() {

    },
    mounted() { },
    methods: {
        //验证成功
        onSuccess(val) {
            var that = this
            this.succTime = (val / 1000).toFixed(2)
            this.succShow = true
            setTimeout(function () {
                that.dialogVisible = false
                that.submitBtn()
            }, 1000)
        },
        // 验证失败
        onFail() {
            this.errShow = true
        },
        // 刷新成功
        onFulfilled() {
            this.succTime = 0
            this.succShow = false
            this.errShow = false
        },
        // 登录
        submitForm(formName) {
            var that = this
            that.$refs[formName].validate((valid) => {
                if (valid) {
                    // 验证通过
                    that.dialogVisible = true
                    that.$nextTick(function () {
                        that.$refs.slideblock.reset()
                    })
                    // this.submitBtn()
                } else {
                    // 验证不通过
                    return false;
                }
            });
        },
        submitBtn() {
            var that = this
            that.btnloading = true
            var params = {
                account: that.loginForm.account,
                password: that.loginForm.password,
            }
            that.$publicApi.userLogin(params).then(res => {
                that.btnloading = false;
                if (res.code == 1000) {
                    that.$store.commit('SET_TOKEN', res.result.token)
                    that.$store.commit('userInfo/SET_USER_INFO', JSON.stringify(res.result.userinfo))
                    that.roleInfo()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 获取菜单
        roleInfo() {
            var that = this
            that.$publicApi.menuList().then(res => {
                that.btnloading = false
                if (res.code == 1000) {
                    that.$store.commit('SET_ROLE', JSON.stringify(res.result))
                    var url = res.result[0].children[0].component_path
                    that.$router.push(url)
                } else {
                    that.$errMsg(res.message)
                }
            })

        },
    },
}
</script>

<style lang='scss' scoped>
.login_main {
    position: relative;
    width: 100%;
    min-width: 724px;
    height: 100%;
    min-height: 700px;
    background-image: url(~@/assets/images/img/login_background.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .main {
        position: absolute;
        top: 0;
        right: 0;
        width: 724px;
        height: 100%;
        padding: 160px 0;
        background: rgba(255, 255, 255, 0.4);
        box-shadow: inset 2px 0px 0px 0px #ffffff;
        backdrop-filter: blur(10px);
        .main_title {
            width: 100%;
            text-align: center;
            font-size: 36px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }
        .main_form {
            width: 100%;
            padding: 0 95px;
            .el-form {
                padding: 55px 0;
                ::v-deep .el-form-item {
                    margin-bottom: 30px;
                }
            }
            .form_title {
                width: 100%;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }
            .form_input {
                width: 100%;
                height: 60px;
                margin-top: 10px;
                .el-input {
                    width: 100%;
                    height: 60px;
                }
                ::v-deep .el-input__inner {
                    height: 60px;
                    line-height: 60px;
                    font-size: 18px;
                    background: #ffffff;
                    border-radius: 10px;
                    border: 1px solid #d8d8d8;
                }
            }
            ::v-deep .el-form-item__error {
                font-size: 16px;
            }
            .form_btn {
                width: 100%;
                height: 70px;
                .btn_txt {
                    width: 100%;
                    height: 100%;
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    box-shadow: 0px 10px 30px 0px rgba(46, 75, 242, 0.39);
                    border-radius: 10px;
                }
            }
        }
    }
}
/*  */
.verify_main {
    position: relative;
    width: 100%;
    .ver_succ {
        position: absolute;
        top: 170px;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        color: #ffff;
        font-size: 12px;
        background: rgba(103, 194, 58, 0.5);
    }
    .ver_err {
        position: absolute;
        top: 170px;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        color: #ffff;
        font-size: 12px;
        background: rgba(245, 108, 108, 0.5);
    }
}
</style>
